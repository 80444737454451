body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 400px){
  .MuiPaper-elevation .MuiCalendarPicker-root,
  .MuiPaper-elevation .rdrCalendarWrapper{
    transform: scale(.95);
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #38AA72;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #38AA72 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ring-mini {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}
.lds-ring-mini div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring-mini div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-mini div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-mini div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring-mini {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overlay-scroll-users-list .os-scrollbar .os-scrollbar-track .os-scrollbar-handle,
.overlay-scroll-list .os-scrollbar .os-scrollbar-track .os-scrollbar-handle,
.overlay-scroll-table .os-scrollbar .os-scrollbar-track .os-scrollbar-handle{
  background: #38AA72;
  opacity: .5;
  transition: all 200ms;
  cursor: grab;
}
.overlay-scroll-users-list .os-scrollbar .os-scrollbar-track .os-scrollbar-handle:hover,
.overlay-scroll-list .os-scrollbar .os-scrollbar-track .os-scrollbar-handle:hover,
.overlay-scroll-table .os-scrollbar .os-scrollbar-track .os-scrollbar-handle:hover{
  opacity: 1;
}

.overlay-scroll-users-list .os-scrollbar-horizontal .os-scrollbar-handle,
  .overlay-scroll-list .os-scrollbar-horizontal .os-scrollbar-handle{
  display: none;
}
.overlay-scroll-table .os-scrollbar-horizontal .os-scrollbar-handle{
  height: 6px;
}
.overlay-scroll-users-list .os-scrollbar-horizontal .os-scrollbar-handle:hover,
.overlay-scroll-list .os-scrollbar-horizontal .os-scrollbar-handle:hover,
.overlay-scroll-table .os-scrollbar-horizontal .os-scrollbar-handle:hover{
  height: 10px;
}
.overlay-scroll-users-list .os-scrollbar-vertical .os-scrollbar-handle,
.overlay-scroll-list .os-scrollbar-vertical .os-scrollbar-handle,
.overlay-scroll-table .os-scrollbar-vertical .os-scrollbar-handle{
  width: 6px;
}
.overlay-scroll-users-list .os-scrollbar-vertical .os-scrollbar-handle:hover,
.overlay-scroll-list .os-scrollbar-vertical .os-scrollbar-handle:hover,
.overlay-scroll-table .os-scrollbar-vertical .os-scrollbar-handle:hover{
  width: 10px;
}